import React from 'react'
import Layout from '../../components/layout/Layout';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import '../careers.css'
import { Link } from 'gatsby';
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function Example() {
  let disqusConfig = {
    url: 'https://www.c4scale.com/blogs/elastic-search-to-s3-data-migration',
    identifier: '1',
    title: 'Elastic Search to AWS S3 — Data Migration - A Glance!',
  }
  return (
    <>
    <head>
        <title>Elastic Search to AWS S3 Cloud Data Migration: A Comprehensive Guide | C4Scale</title>
        <meta name="description" content="Learn how to efficiently migrate billions of JSON documents from Elastic Search to AWS S3 using managed services. Discover a seamless approach for data flattening and Parquet conversion." />
        <meta name="keywords" content="Elastic Search migration, AWS S3 data migration, big data migration, JSON to Parquet conversion, AWS Kinesis, AWS Lambda, AWS Glue, data flattening, cloud data migration, ETL process" />
      </head>
      <Layout>
     <div>
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 relative">
          <div className="bg-cover h-64 text-center overflow-hidden" style={{height: '450px', backgroundImage: 'url("https://cdn2.vectorstock.com/i/1000x1000/31/86/data-transfer-vector-27123186.jpg")'}} title="Woman holding a mug">
          </div>
          <div className="max-w-4xl mx-auto">
            <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
              <div className="my-10">
                <span className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  Elastic search
                </span>, <span className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  S3
                </span>
                <h1 className="text-gray-900 font-bold text-3xl mb-2">Elastic Search to AWS S3 — Data Migration - A Glance!</h1>
                <p className="text-gray-700 text-xs mt-2">Written By: <a href="https://www.linkedin.com/in/chakravarthyvp/" className="text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out" target='blank'>
                Chakravarthy Varaga
                  </a></p>
                <p className="text-21 leading-8 my-5">
                  Enterprises today need their data monetized, if not, leverage on the insights from the enormous amount of data to generate newer incomes from newer products. Building infrastructure, platforms to gather, clean, prepare, process, standardize and making the data (big data) available for heterogeneous stake holders amidst the ecosystem that is vastly huge, constantly changing, is a daunting task. The open source communities are churning out new components, frameworks in this ecosystem. The cloud providers like AWS, Azure, GCP release new services that lets the organization, adopting these services, focus on their business than building, managing these services. This article focuses on Data migration in the cloud and how flexible that could be using the managed services.</p>
                <h3 className="text-2xl font-bold my-5">Requirement</h3>
                <ul style={{listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px'}}>
                    <li>A billion+ json documents from each environment (integration, performance, production) to move from Elastic Search to AWS S3.</li>
                    <li>json documents need to be flattened</li>
                    <li>stored in Parquet format</li>
                    <li>no latency restrictions</li>
                </ul>
                <img className="w-full h-full object-cover" src="https://miro.medium.com/max/600/1*KoiWzRtSsBMtR4DV8b9J8Q.jpeg" alt=""/>
                <p className="text-21 leading-8 my-5">
                  There are multiple solutions to this problem. Some of them are listed below:</p>
                <blockquote className="border-l-4 text-21 italic leading-8 my-5 p-5 text-indigo-600">
                    <p>ElasticSearch → LogStash → Apache Kafka → Apache Spark → S3</p>
                    <p>ElasticSearch → LogStash → Apache Kafka → Secor → S3</p>
                </blockquote>
                <p className="text-21 leading-8 my-5">
                  KafkaConnect can be used as another integration option, instead of Spark and Secor, however there are no officially supported Parquet sink converter/connector that can store in S3.</p>
                   <p className="text-21 leading-8 my-5">
                   <a href="https://github.com/pinterest/secor" target="_blank" rel="noopener noreferrer">Secor </a> 
                   is an open-source component from pinterest that can run as a service ingesting data from Kafka and provides out-of-box configuration options to tweak caching, data formats, partitioning etc.,</p>
                  <p className="text-21 leading-8 my-5">
                  With both options, standing and operating the infrastructure for Kafka, Spark and Secor bears costs even if the migration is once off. Code needs to be written wiring these components and the efforts involved has costs associated with it. After all the infrastructure needs to be torn down if not used.</p>
                  <p className="text-21 leading-8 my-5">
                  With Apache Spark, the programmatic control, with it’s distributed processing power, is a boon, as it gives fine-grained control over partitioning, ordering, caching etc., however the developer efforts involved including CI is inevitable.</p>
                  <p className="text-21 leading-8 my-5">
                  Researching through some of the AWS services proved to be seamless and effortless for this once off activity.</p>
                  <blockquote className="border-l-4 text-21 italic leading-8 my-5 p-5 text-indigo-600">
                    <p>ElasticSearch → LogStash → AWS Kinesis → AWS Kinesis Firehose (lambda, glue)→ S3</p>
                </blockquote>
                <h3 className="text-2xl font-bold my-5">Logstash</h3>
                <p className="text-21 leading-8 my-5">
                <a href="https://www.elastic.co/products/logstash" target="_blank" rel="noopener noreferrer"><strong>Logstash</strong> </a> — is a product from Elastic Search that lets you stash data in and out of Elastic Search. The plugin based programming model makes it easy to configure the input and the output of those plugins. Basically you can move data to other streaming, messaging systems like Kafka, Kinesis, NoSql databases etc., The configuration/code snippet is further down in this article.
                </p>

                <h3 className="text-2xl font-bold my-5">Kinesis Firehose</h3>
                <p className="text-21 leading-8 my-5">
                <a href="https://aws.amazon.com/kinesis/data-firehose/" target="_blank" rel="noopener noreferrer"><strong>Kinesis Data-Firehose</strong> </a>  — Data-Firehose is a managed delivery stream that lets you capture, transform data from streams, convert and store in a destination. It has a source, processing/transformation stream and a destination to store. In this case the source is Kinesis Stream that we created earlier (where the json data from ES will be ingested into through logstash). Firehose can batch, compress, encrypt data before storing the data.
Firehose provides facility to perform the following pipeline work
Data Source → Transform →Data Conversion →Store                </p>

                <h3 className="text-2xl font-bold my-5">Lambda (Transform)</h3>
                <p className="text-21 leading-8 my-5">
                <a href="https://aws.amazon.com/lambda/" target="_blank" rel="noopener noreferrer"><strong>Lambda </strong> </a>  — are Functions that are serverless, managed (compute service). You could write just the code you want to execute without worrying about its deployment, management, operations, provisioning of servers.
The processing (flattening json document), in the firehose, is run by Lambda. Python, Node.js, Ruby, .Net are widely used platforms while Java is a latest addition in this list. Creating a lambda from the console is simple as well. Choose the platform (python in our case) to run, writing the code (below) and fire away.
                </p>

                <h3 className="text-2xl font-bold my-5">AWS Glue (Data conversion)</h3>
                <p className="text-21 leading-8 my-5">
                <a href="https://aws.amazon.com/glue/" target="_blank" rel="noopener noreferrer"><strong>AWS Glue</strong> </a> is an ETL service from AWS that includes meta-data (table definition, schema) management in what is called as Data Catalog. The transformed data from the Lambda needs to be converted to parquet format.
Firehose supports out-of-box serde formats to convert to Parquet or ORC. Data conversion to Parquet needs a schema to confirm to. AWS Glue can be used to create a database and the schema through the console.
                </p>

                <h3 className="text-2xl font-bold my-5">S3 (Store)</h3>
                <p className="text-21 leading-8 my-5">
                <a href="https://aws.amazon.com/s3/" target="_blank" rel="noopener noreferrer"><strong>S3 (Simple Storage Service)</strong> </a> — is an object store with 11 9s durability. Configuration of this is as simple as specifying the bucket (root directory), keys (directory like paths) where the data has to be stored, buffering etc.,
Now that the pipeline is created, it’s time to create the logstash configuration to move data. Apparently, there is no officially supported logstash kinesis output plugin however there’s this opensource plugin that could be used. Sample logstash configuration using this plugin is below:</p>
                <h3 className="text-2xl font-bold my-5">Monitoring/Diagnosis</h3>
                <p className="text-21 leading-8 my-5">
                AWS Glue is an ETL service from AWS that includes meta-data (table definition, schema) management in what is called as Data Catalog. The transformed data from the Lambda needs to be converted to parquet format.
Firehose supports out-of-box serde formats to convert to Parquet or ORC. Data conversion to Parquet needs a schema to confirm to. AWS Glue can be used to create a database and the schema through the console.
                </p>

                <h3 className="text-2xl font-bold my-5">Performance/Throughput optimizations</h3>
                <p className="text-21 leading-8 my-5">
                Here are some parameters to consider tweaking to achieve an overall throughput or performance efficiency. That is another article !
LogStash(Pipeline workers, batch sizes, jvm memory). Kinesis Streams(Shard count). Lambda (Reducing the processing time is key,The max., concurrent executions of lambda is equal to max shard count.)
Firehose (Buffer sizes, timeouts determine the file sizes on S3).
<p className="text-21 leading-8 my-5">Instantiating the services took me roughly a day. Ready to migrate !</p>
                </p>
                <div className="mt-6 mb-6">
                    <Link
                      to="https://calendly.com/chakravarthy-varaga/introductory-call" target='blank'
                      className="w-max flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Talk to our product
                    </Link>
                  </div>
                <span className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  #aws
                </span><span className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  #cloud data migration
                </span><span className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  #elastic search
                </span>
              </div>
              {/* <div className="my-10"> */}
                
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={{ marginTop: "20px", padding: "0 20px", marginBottom: "20px" }}
        >
          <Link to="/blogs/the-data-quality-jigsaw" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold" }} />
              <Typography variant="h6" sx={{ fontSize: "18px" }}>Previous</Typography>
            </Box>
          </Link>
        </Stack>
      <section style={{padding: '0 4rem'}}>
      <CommentCount config={disqusConfig} />
      <Disqus config={disqusConfig} />
      </section>
      </Layout>
      </>
  )
}
